import React, { useState } from "react";

import CategoryLink from "./CategoryLink";

interface Props {
  categories: Category[];
  category: string;
  setCategory: (category: string) => void;
}

export default function CategoryFilter(props: Props) {
  const [expanded, setExpanded] = useState(false);

  const handleExpand = (evt: React.MouseEvent) => {
    evt.preventDefault();
    setExpanded(!expanded);
  };

  const toggleCategory = (category: string) => {
    if (category == props.category) {
      props.setCategory("");
    } else {
      props.setCategory(category);
    }
  };

  return (
    <div className="category-filter">
      <a href="#" className="category-filter-toggle" onClick={handleExpand}>
        Velg kategori
      </a>
      {expanded && (
        <div className="categories">
          {props.categories.map((c) => (
            <CategoryLink
              key={c.name}
              name={c.name}
              label={c.label}
              current={c.name == props.category}
              toggleCategory={toggleCategory}
            />
          ))}
        </div>
      )}
    </div>
  );
}
