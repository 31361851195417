import React, { useState } from "react";

import { Navigation, Pagination, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import Picture from "./Picture";

interface Slide {
  id: number;
  image: Picture;
  caption?: string;
}

interface SlideChangeEvent {
  activeIndex: number;
}

interface Props {
  slides: Slide[];
}

export default function Carousel(props: Props) {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSlideChange = (evt: SlideChangeEvent) => {
    setActiveIndex(evt.activeIndex);
  };

  const currentSlide = props.slides[activeIndex];

  return (
    <div className="carousel">
      <Swiper
        modules={[Navigation, Pagination, A11y]}
        navigation
        pagination={{ clickable: true }}
        spaceBetween={50}
        slidesPerView={1}
        onSlideChange={handleSlideChange}>
        {props.slides.map((slide) => (
          <SwiperSlide key={slide.id}>
            <figure>
              <Picture image={slide.image} />
            </figure>
          </SwiperSlide>
        ))}
      </Swiper>
      {currentSlide.caption && (
        <figcaption key={currentSlide.id}>{currentSlide.caption}</figcaption>
      )}
    </div>
  );
}
