import React, { useState } from "react";

import Article from "./News/Article";
import CategoryFilter from "./News/CategoryFilter";
import ProgressBar from "./News/ProgressBar";

interface FilterState {
  tag: string;
  page: number;
}

interface Props {
  articles: Article[];
  categories: Category[];
}

function activeCategories(categories: Category[], articles: Article[]) {
  const usedTags = [...new Set(articles.flatMap((a) => a.tag_names))];
  return categories.filter((c) => usedTags.indexOf(c.name) !== -1);
}

function filterArticles(articles: Article[], state: FilterState) {
  if (state.tag) {
    return articles.filter((a) => a.tag_names.indexOf(state.tag) !== -1);
  } else {
    return articles;
  }
}

export default function News(props: Props) {
  const [filterState, setFilterState] = useState<FilterState>({
    page: 1,
    tag: ""
  });

  const filteredArticles = filterArticles(props.articles, filterState);

  const [topArticle, ...articles] = filteredArticles;

  const perPage = 9;
  const pages = Math.ceil(articles.length / perPage);

  const setTag = (tag: string) => {
    setFilterState({ tag: tag, page: 1 });
  };

  const handleNextPage = (evt: React.MouseEvent) => {
    evt.preventDefault();
    if (filterState.page < pages) {
      setFilterState({ ...filterState, page: filterState.page + 1 });
    }
  };

  const paginatedArticles = articles.slice(0, filterState.page * perPage);

  return (
    <>
      <CategoryFilter
        categories={activeCategories(props.categories, props.articles)}
        category={filterState.tag}
        setCategory={setTag}
      />
      {topArticle && (
        <Article
          key={topArticle.id}
          className="top-article"
          article={topArticle}
          headingLevel="h2"
          picture={topArticle.picture}
          pictureSizes="(max-width: 900px) 100vw, 66vw"
        />
      )}
      {paginatedArticles && (
        <section className="articles-grid grid-3">
          {paginatedArticles.map((a) => (
            <Article
              key={a.id}
              className="grid-item"
              article={a}
              headingLevel="h3"
              picture={a.thumbnail}
              pictureSizes="(max-width: 900px) 100vw, 33vw"
            />
          ))}
        </section>
      )}
      {filterState.page < pages && (
        <div className="load-more">
          <ProgressBar progress={filterState.page / pages} />
          <button onClick={handleNextPage}>Vis mer</button>
        </div>
      )}
    </>
  );
}
