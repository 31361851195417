import { Controller } from "stimulus";

export default class ShareController extends Controller {
  facebook() {
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" +
        encodeURIComponent(this.url()),
      "facebook-share-dialog",
      "width=626,height=436"
    );
  }

  url() {
    return window.location.href;
  }
}
