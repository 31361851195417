import React from "react";

interface Props {
  progress: number;
}

export default function ProgressBar(props: Props) {
  const style = {
    width: `${props.progress * 100}%`
  };
  return (
    <div className="progress-bar">
      <div className="progress" style={style}></div>
    </div>
  );
}
