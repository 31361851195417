import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
  headingLevel?: string;
}

export default function Heading(props: Props) {
  if (props.headingLevel == "h2") {
    return <h2>{props.children}</h2>;
  } else {
    return <h3>{props.children}</h3>;
  }
}
