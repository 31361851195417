import { Controller } from "stimulus";

export default class HeaderController extends Controller {
  scrollPosition: number;
  scrollDirection: number;
  scrollSwitch: number;
  scrollThreshold: number;

  initialize() {
    this.scrollPosition = 0;
    this.scrollDirection = 1;
    this.scrollSwitch = 0;

    this.scrollThreshold = 50;
  }

  handleScroll() {
    if (window.scrollY) {
      const scrollOffset = window.scrollY - this.scrollPosition;
      this.scrollPosition = window.scrollY;

      if (scrollOffset !== 0) {
        const scrollDirection = scrollOffset / Math.abs(scrollOffset);
        if (scrollDirection !== this.scrollDirection) {
          this.scrollSwitch = window.scrollY;
          this.scrollDirection = scrollDirection;
        }
      }

      const scrollDistance = window.scrollY - this.scrollSwitch;

      if (scrollDistance <= this.scrollThreshold * -1 || window.scrollY < 30) {
        this.element.classList.remove("hide");
      } else if (
        scrollDistance >= this.scrollThreshold &&
        window.scrollY >= 30
      ) {
        this.element.classList.add("hide");
      }
    }
  }

  toggleExpanded() {
    this.element.classList.toggle("expanded");
  }
}
