import React from "react";

interface Props {
  name: string;
  label: string;
  current: boolean;
  toggleCategory: (name: string) => void;
}

export default function CategoryLink(props: Props) {
  const handleClick = (evt: React.MouseEvent) => {
    evt.preventDefault();
    props.toggleCategory(props.name);
  };

  const classes = ["category", "button"];
  if (props.current) {
    classes.push("current");
  }

  return (
    <a href="#" className={classes.join(" ")} onClick={handleClick}>
      {props.label}
    </a>
  );
}
