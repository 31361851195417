import React from "react";

import Picture from "../Picture";
import Heading from "./Heading";

interface Props {
  article: Article;
  picture: Picture;
  pictureSizes: string;
  className: string;
  headingLevel: string;
}

export default function Article(props: Props) {
  const { name, brand_names, excerpt_html, url } = props.article;

  return (
    <article className={props.className}>
      <div className="image">
        {props.picture && (
          <a href={url}>
            <Picture image={props.picture} sizes={props.pictureSizes} />
          </a>
        )}
      </div>
      <div className="content">
        <div className="brand-names">{brand_names}</div>
        <Heading headingLevel={props.headingLevel}>
          <a href={url}>{name}</a>
        </Heading>
        <div
          className="teaser"
          dangerouslySetInnerHTML={{ __html: excerpt_html }}
        />
        <a href={url} className="read-more button small">
          Les mer
        </a>
      </div>
    </article>
  );
}
