// Entry point for the build script in your package.json

// import React from "react";

// Rails UJS
// import Rails from "@rails/ujs";
// Rails.start();

// Responsive embeds
import responsiveEmbeds from "./lib/responsiveEmbeds";
responsiveEmbeds();

// Grid overlay
import gridOverlay from "./lib/gridOverlay";
gridOverlay();

// Stimulus
import { Application } from "stimulus";
import ExpandableController from "./controllers/ExpandableController";
import HeaderController from "./controllers/HeaderController";
import ShareController from "./controllers/ShareController";
const application = Application.start();
application.register("expandable", ExpandableController);
application.register("header", HeaderController);
application.register("share", ShareController);

// React
import { FC } from "react";
import * as Components from "./components";
import ReactRailsUJS from "react_ujs";
ReactRailsUJS.getConstructor = (className: string) =>
  Components[className] as FC;
